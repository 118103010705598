var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "FormComponent",
      class: [_vm.themeClass, "orientation--" + _vm.orientation]
    },
    [
      _vm.orientation === "column"
        ? _c(
            "label",
            { attrs: { for: _vm.tag } },
            [
              _vm.icon
                ? _c("font-awesome-icon", {
                    attrs: { icon: _vm.icon, pull: "left" }
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.label) + " ")
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-row", class: [{ underline: _vm.underline }] },
        [
          _vm.orientation === "row"
            ? _c(
                "div",
                { staticClass: "icon-label" },
                [
                  _vm.icon
                    ? _c("font-awesome-icon", {
                        attrs: { icon: _vm.icon, pull: "left" }
                      })
                    : _vm._e(),
                  _vm.label
                    ? _c("label", [_vm._v(_vm._s(_vm.label))])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._t("default")
        ],
        2
      ),
      _vm._t("error"),
      _c("ValidationError", { attrs: { errors: _vm.errors, name: "tag" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }