<template>
  <div
    class="FormComponent"
    :class="[themeClass, `orientation--${orientation}`]"
  >
    <label
      v-if="orientation === 'column'"
      :for="tag"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="icon"
        pull="left"
      />

      {{ label }}
    </label>

    <div
      class="form-row"
      :class="[{underline: underline}]"
    >
      <div
        v-if="orientation === 'row'"
        class="icon-label"
      >
        <font-awesome-icon
          v-if="icon"
          :icon="icon"
          pull="left"
        />

        <label v-if="label">{{ label }}</label>
      </div>

      <slot />
    </div>
    <slot name="error" />

    <ValidationError
      :errors="errors"
      name="tag"
    />
  </div>
</template>

<script>
import ValidationError from '@/components/common/ValidationError';

export default {
  components: {
    ValidationError,
  },

  props: {
    label: {
      type: String,
      default: undefined,
    },

    icon: {
      type: Array,
      default: undefined,
    },

    tag: {
      type: String,
      required: true,
    },

    errors: {
      type: Array,
      required: true,
    },

    orientation: {
      type: String,
      default: 'column',
      validator: orientation => ['row', 'column'].includes(orientation),
    },

    underline: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.FormComponent{
  margin-bottom: var(--spacing-l);
  max-width: 600px;

  &.theme--light{
    --local-color: var(--color-neutral-darkest);
    --local-border-color: var(--color-neutral-mid-dark);
   }

  &.theme--dark{
    --local-color: var(--color-neutral-lightest);
    --local-border-color: var(--color-neutral-mid-light);
  }

  color: var(--local-color);

  label{
    margin-bottom: var(--spacing-xs);
    display: flex;
    font-weight: 600;
    align-items: baseline;
    font-family: var(--font-family-primary);
  }

  .form-row {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    .icon-label{
      margin-left: var(--spacing-xs);
    }

    &.underline{
      border-bottom: 1px solid var(--local-border-color);
    }

    label{
      font-weight: 500;
      margin-right: var(--spacing-xs);
    }

    @media #{$breakpoint-sm-max} {
      flex-direction: column;
    }
  }
}
</style>
