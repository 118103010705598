<template>
  <div
    class="LoginInput"
    :class="[themeClass]"
  >
    <input
      :id="name"
      v-model="innerValue"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :pattern="pattern"
      :title="title"
      :autocomplete="autocomplete"
      @keydown="$emit('move-selector', $event)"
    >
  </div>
</template>

<script>

export default {
  name: 'LoginInput',

  props: {
    type: {
      type: String,
      default: 'text',
    },

    tag: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: 'info',
    },

    required: {
      type: Boolean,
      default: false,
    },

    pattern: {
      type: String,
      default: undefined,
    },

    isAutocomplete: {
      type: Boolean,
      default: true,
    },

    value: {
      type: String,
      default: undefined,
    },

    title: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    name() {
      return `form-${this.tag}`;
    },

    autocomplete() {
      return this.isAutocomplete ? 'on' : 'off';
    },

    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginInput {
  flex: 1;
  margin-bottom: var(--spacing-2xs);

  &.theme--light{
    --local-color: var(--color-neutral-darkest);
    --local-placeholder-color: var(--color-neutral-mid-dark);
   }

  &.theme--dark{
    --local-color: var(--color-neutral-lightest);
    --local-placeholder-color: var(--color-neutral-mid);
  }

  input {
    background: transparent;
    color: var(----local-color);
    padding: var(--spacing-2xs) var(--spacing-s);
    margin-bottom: $spacing-s;
    height: 2rem;

    width: 100%;

    font-size: var(--font-size-s);
    border: 0;
    border-bottom: solid 1px;
    -webkit-appearance: none;
    touch-action: manipulation;
    transition: all 0.2s;

    &::placeholder{
      color: var($color-neutral-light);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      box-shadow: inset 0 0 0 50px var(--color---color-neutral-lightest);
    //  border-radius: 5px;
      // margin-left: var(--spacing-s);
    }

    @media #{$breakpoint-lg-max} {
         min-width: 330px;


  }
  }


  input:focus {
    outline: 0;
    padding-left: var(--spacing-s);
  }
}
</style>
